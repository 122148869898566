import Vue from 'vue'
import Vuex from 'vuex'
 
Vue.use(Vuex)
 
 const store =  new Vuex.Store({
  state: {
    trainingFootageId:localStorage.getItem('trainingFootageId'),
    trainingId:localStorage.getItem('trainingId'),
    chapterId:localStorage.getItem('chapterId')
  },
  actions: {
    changeTrainingFootageId(ctx, trainingFootageId) {
      ctx.commit('changeTrainingFootageIds', Number(trainingFootageId)) // 调用mutations中的方法改变city
    },
    changeTrainingId(ctx, trainingId) {
      ctx.commit('changeTrainingIds', Number(trainingId))  
    },
    changeChapterId(ctx, chapterId) {
      ctx.commit('changeChapterIds', Number(chapterId)) 
    },        
  },
  mutations: {
    changeTrainingFootageIds(state, trainingFootageId) {
      localStorage.setItem('trainingFootageId',Number(trainingFootageId))
    },
    changeTrainingIds(state, trainingId) {
      localStorage.setItem('trainingId',Number(trainingId))
    },
    changeChapterIds(state, chapterId) {
      localStorage.setItem('chapterId',Number(chapterId))
    }
  },
  getters: { // 类似计算属性，计算state中的数据提供新的数据
    basicId: state => { return {trainingFootageId:Number(state.trainingFootageId),trainingId:Number(state.trainingId),chapterId:Number(state.chapterId)}  }
  }
})
export default store