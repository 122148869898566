export default {
  route: {
    dashboard: 'Dashboard',
    introduction: 'Introduction',
    documentation: 'Documentation',
    guide: 'Guide',
    permission: 'Permission',
    pagePermission: 'Page Permission',
    directivePermission: 'Directive Permission',
    icons: 'Icons',
    components: 'Components',
    componentIndex: 'Introduction',
    tinymce: 'Tinymce',
    markdown: 'Markdown',
    jsonEditor: 'JSON Editor',
    dndList: 'Dnd List',
    splitPane: 'SplitPane',
    avatarUpload: 'Avatar Upload',
    dropzone: 'Dropzone',
    sticky: 'Sticky',
    countTo: 'CountTo',
    componentMixin: 'Mixin',
    backToTop: 'BackToTop',
    dragDialog: 'Drag Dialog',
    dragSelect: 'Drag Select',
    dragKanban: 'Drag Kanban',
    charts: 'Charts',
    keyboardChart: 'Keyboard Chart',
    lineChart: 'Line Chart',
    mixChart: 'Mix Chart',
    example: 'Example',
    nested: 'Nested Routes',
    menu1: 'Menu 1',
    'menu1-1': 'Menu 1-1',
    'menu1-2': 'Menu 1-2',
    'menu1-2-1': 'Menu 1-2-1',
    'menu1-2-2': 'Menu 1-2-2',
    'menu1-3': 'Menu 1-3',
    menu2: 'Menu 2',
    Table: 'Table',
    dynamicTable: 'Dynamic Table',
    dragTable: 'Drag Table',
    inlineEditTable: 'Inline Edit',
    complexTable: 'Complex Table',
    treeTable: 'Tree Table',
    customTreeTable: 'Custom TreeTable',
    tab: 'Tab',
    form: 'Form',
    createArticle: 'Create Article',
    editArticle: 'Edit Article',
    articleList: 'Article List',
    errorPages: 'Error Pages',
    page401: '401',
    page404: '404',
    errorLog: 'Error Log',
    excel: 'Excel',
    exportExcel: 'Export Excel',
    selectExcel: 'Export Selected',
    uploadExcel: 'Upload Excel',
    zip: 'Zip',
    pdf: 'PDF',
    exportZip: 'Export Zip',
    theme: 'Theme',
    clipboardDemo: 'Clipboard',
    i18n: 'I18n',
    externalLink: 'External Link'
  },
  navbar: {
    logOut: 'Log Out',
    changePassword:'Change Password',
    dashboard: 'Dashboard',
    github: 'Github',
    screenfull: 'Screenfull',
    theme: 'Theme',
    size: 'Global Size',
    train:'Train',
    task:'Task',
    news:'News',
    usernames:'Username',
    mobile:'Mobile',
    mail:'Mail',
    oldPassword:'Old Password',
    newPassword:'New Password',
    confirmPassword:'Confirm Password'
  },
  login: {
    title: 'Login Form',
    logIn: 'Log in',
    username: 'Username or mailbox',
    password: 'Password',
    any: 'any',
    thirdparty: 'Or connect with',
    thirdpartyTips: 'Can not be simulated on local, so please combine you own business simulation! ! !',
    platformName:'Platform Name',
    verificationCode:'Please enter the verification code',
    reName:'Remember user name',
    verificationCodeError:"Verification code error",
    formError:"Please enter the complete form",
    logIn:'Please login'
  },
  documentation: {
    documentation: 'Documentation',
    github: 'Github Repository'
  },
  permission: {
    roles: 'Your roles',
    switchRoles: 'Switch roles',
    tips: 'In some cases it is not suitable to use v-permission, such as element Tab component or el-table-column and other asynchronous rendering dom cases which can only be achieved by manually setting the v-if.'
  },
  guide: {
    description: 'The guide page is useful for some people who entered the project for the first time. You can briefly introduce the features of the project. Demo is based on ',
    button: 'Show Guide'
  },
  components: {
    documentation: 'Documentation',
    tinymceTips: 'Rich text editor is a core part of management system, but at the same time is a place with lots of problems. In the process of selecting rich texts, I also walked a lot of detours. The common rich text editors in the market are basically used, and the finally chose Tinymce. See documentation for more detailed rich text editor comparisons and introductions.',
    dropzoneTips: 'Because my business has special needs, and has to upload images to qiniu, so instead of a third party, I chose encapsulate it by myself. It is very simple, you can see the detail code in @/components/Dropzone.',
    stickyTips: 'when the page is scrolled to the preset position will be sticky on the top.',
    backToTopTips1: 'When the page is scrolled to the specified position, the Back to Top button appears in the lower right corner',
    backToTopTips2: 'You can customize the style of the button, show / hide, height of appearance, height of the return. If you need a text prompt, you can use element-ui el-tooltip elements externally',
    imageUploadTips: 'Since I was using only the vue@1 version, and it is not compatible with mockjs at the moment, I modified it myself, and if you are going to use it, it is better to use official version.'
  },
  table: {
    dynamicTips1: 'Fixed header, sorted by header order',
    dynamicTips2: 'Not fixed header, sorted by click order',
    dragTips1: 'The default order',
    dragTips2: 'The after dragging order',
    title: 'Title',
    importance: 'Imp',
    type: 'Type',
    remark: 'Remark',
    search: 'Search',
    add: 'Add',
    export: 'Export',
    reviewer: 'reviewer',
    id: 'ID',
    date: 'Date',
    author: 'Author',
    readings: 'Readings',
    status: 'Status',
    actions: 'Actions',
    edit: 'Edit',
    publish: 'Publish',
    draft: 'Draft',
    delete: 'Delete',
    cancel: 'Cancel',
    confirm: 'Confirm'
  },
  errorLog: {
    tips: 'Please click the bug icon in the upper right corner',
    description: 'Now the management system are basically the form of the spa, it enhances the user experience, but it also increases the possibility of page problems, a small negligence may lead to the entire page deadlock. Fortunately Vue provides a way to catch handling exceptions, where you can handle errors or report exceptions.',
    documentation: 'Document introduction'
  },
  excel: {
    export: 'Export',
    selectedExport: 'Export Selected Items',
    placeholder: 'Please enter the file name(default excel-list)'
  },
  zip: {
    export: 'Export',
    placeholder: 'Please enter the file name(default file)'
  },
  pdf: {
    tips: 'Here we use window.print() to implement the feature of downloading pdf.'
  },
  theme: {
    change: 'Change Theme',
    documentation: 'Theme documentation',
    tips: 'Tips: It is different from the theme-pick on the navbar is two different skinning methods, each with different application scenarios. Refer to the documentation for details.'
  },
  tagsView: {
    refresh: 'Refresh',
    close: 'Close',
    closeOthers: 'Close Others',
    closeAll: 'Close All'
  },
  messageStatus:{
    success:'Success!',
    fail:'Fail!'
  },
  home:{
    trainingProgress:'Training Progress',
    complete:"You finished",
    train:'Training',
    total:'Total',
    term:'term',
    continueLearning:'Continue Learning',
    message:'Unread message',
    more:'More',
    unread:'Unread',
    myTraining:'My Training',
    trainingName:'Training Name',
    status:'status',
    deadline:'deadline',
    startTrain:'Start Train',
    myTask:'My Task',
    taskName:'Task Name',
    type:'type',
    startTime:'Start Time',
    endTime:'End Time',
    startTask:'Start Task',     
  },
  train:{
    allTrain:'All Training',
    all:'All',
    activated:'Activated',
    closed:'Closed',
    search:'Please enter the search content',
    courseIntroduction:'Course  Introduction',
    currentProgress:'Current Progress',
    catalogue:'Catalogue',
    next:'Next Section',
    describe:'Describe',
    check:'Check',
    downloadCertificate:'Download Certificate',
    startTraining:'Start Training',
    accomplish:'You have completed {durationTimeDesc} for {title}',
    Congratulations: 'Congratulations',
    continue:'continue',
    redo:'redo',
    retest:'retest',
    training: 'Complete the training',
    Retrospective:'Retrospective training',
    explain:'Of the {totalNum} questions submitted this time, I got {rightNum} correct. You need to get at least {minRightNum} questions right before you can continue training.',
    explainAll:'Of the {totalNum} questions submitted this time, I got {rightNum} correct.',
    explainTo:'A total of {totalNum} is submitted this time, and {rightNum} is correct. Can be redone or continued'
  },
  task:{
    allTask:'All Task',
    taskName:'Task Name',
    status:'Status',
    type:'Type',
    startTime:'Start Time',
    endTime:'End Time',
    operation:'Operation',
    completeTask:'Complete Task',
    see:'See',
    enterTaskName:'Enter Task Name',
    searchType:'Search Type',
    searchStatus:'Search Status',
    search:'Search',
    questionnaire:'Questionnaire',
    knowledge:'Knowledge',
    test:'Test',
    choose:'Please Select',
    close: 'closed',
    submit:'Submit',
    resubmit:'resubmit'    
  },
  news:{
    allNews:'All News',
    all:'All',
    unread:'Unread',
    read:'Read',
    sender:'Sender',
    messageSubject:'Message Subject',
    sendingTime:'Sending Time',
    remark:'Mark all as read',
    delete:'Delete',
    return:'Return to list'    
  }  
}
