export default {
  route: {
    dashboard: '首页',
    page401: '401',
    page404: '404'
  },
  navbar: {
    logOut: '退出登录',
    changePassword:'修改密码',
    dashboard: '首页',
    train:'培训',
    task:'任务',
    news:'消息',
    usernames:'用户名',
    mobile:'手机号',
    mail:'邮箱',
    oldPassword:'旧密码',
    newPassword:'新密码',
    confirmPassword:'确认新密码',
  },
  login: {
    title: '系统登录',
    logIn: '登录',
    username: '请输入邮箱',
    password: '请输入密码',
    verificationCode:'请输入验证码',
    reName:'记住用户名',
    verificationCodeError:"验证码错误",
    verificationMobileError:"手机号格式不正确",
    formError:"请输入完整表单",
    logIn:'登录'
  },
  table: {
    cancel: '取 消',
    confirm: '确 定'
  },
  common:{
    pleaseSelect:'请选择',
    pleaseInput:'请输入',
    pleaseInputText:'请输入内容'
  },
  home:{
    trainingProgress:'培训进度',
    complete:"你完成",
    train:'项培训',
    total:'总共',
    term:'项',
    continueLearning:'继续学习',
    message:'未读消息',
    more:'更多',
    unread:'未读',
    myTraining:'我的培训',
    trainingName:'培训名称',
    status:'状态',
    deadline:'截止日期',
    startTrain:'开始培训',
    myTask:'我的任务',
    taskName:'任务名称',
    type:'类型',
    startTime:'开始日期',
    endTime:'结束日期',
    startTask:'开始任务', 
  },
  train:{
    allTrain:'全部培训',
    all:'全部',
    activated:'已激活',
    closed:'已关闭',
    search:'请输入搜索内容',
    courseIntroduction:'课程详情',
    currentProgress:'当前进度',
    catalogue:'目录',
    next:'下一节',
    pre1:'上一个',
    next1:'下一个',
    describe:'章节详情描述',
    pleaseFinishPre:'请先完成前面的章节',
    check:'查看',
    downloadCertificate:'下载证书',
    startTraining:'开始培训',
    Congratulations: '恭喜你',
    continue:'继续',
    rePlay:'重播',
    redo:'重做',
    retest:'重新测验',
    Retrospective:'回顾培训',
    training: '完成培训',
    accomplish:'您已完成{durationTimeDesc}的{title}',
    questionTotal:'共{totalNum}题',
    mustAnswerAllQuestions:'请填写所有试题',
    explain:'本次提交共{totalNum}题，做对了{rightNum}题。至少需要做对{minRightNum}题才可以继续培训。',
    explainAll:'本次提交共{totalNum}题，做对了{rightNum}题。',
    explainTo: '本次提交共{totalNum}题，做对了{rightNum}题。可以重做或继续'
  },
  task:{
    allTask:'全部任务',
    taskName:'任务名称',
    status:'状态',
    type:'类型',
    startTime:'开始时间',
    endTime:'结束时间',
    operation:'操作',
    completeTask:'完成任务',
    see:'查看',
    enterTaskName:'请输入任务名称',
    searchType:'请选择任务类型',
    searchStatus:'请输入状态',
    search:'搜索',
    questionnaire:'调查问卷',
    knowledge:'知识竞赛',
    test:'考试',
    choose:'请选择',
    submit:'提交',
    close: '已关闭',
    resubmit:'重新提交',
    unStart:'未开始',
  },
  news:{
    allNews:'全部消息',
    all:'全部',
    unread:'未读',
    read:'已读',
    sender:'发送人',
    messageSubject:'消息主题',
    sendingTime:'发送时间',
    remark:'全部标记为已读',
    delete:'删除',
    return:'返回列表' 
  }
}
