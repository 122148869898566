<template>
  <div id="app">
    <!-- <keep-alive> -->
     <router-view/>
     <!-- </keep-alive> -->
  </div>
</template>

<script>


export default {
  name: 'App',
  components: {
    
  }
}
</script>

<style lang='scss' rel='stylesheet/scss' >
body{
  margin: 0;
  background-color: #f6f6f6;
  color: #333333;
}

</style>
